import styled from 'styled-components';

export const DemoErrorText = styled.div`
  height: 180px;
  color: var(--primary-bg-5);
  border-radius: 6px;
  overflow: hidden;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 8px;
  @media (max-width: 1600px) {
    height: 180px;
  }
`;
