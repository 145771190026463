import styled from '@shared_medialab/ui_components';

export const StyledProjectsContainer = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

export const StyledCopy = styled.div`
  opacity: 0;
`;

export const StyledEmailsContainer = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  z-index: 999;
  position: relative;
  &:hover {
    padding-right: 0;
    ${StyledCopy} {
      opacity: 1;
    }
  }
`;

export const StyledFooter = styled.div`
  height: 100%;
  padding: 10px;
  display: flex;
  justify-content: flex-end;
`;
