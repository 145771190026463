import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import { USERS_FILTERS } from 'pages/UserManagement/constants';
import { parseJson } from 'utils/object';
import { UsersListFiltersState, OnChange } from './types';
import { removeSpaces } from 'utils/removeSpaces';

export const defaultUsersFilters = {
  name: '',
  role: [],
  status: []
};

const initialState: UsersListFiltersState = {
  filters: {
    ...defaultUsersFilters
  },
  ...parseJson(localStorage.getItem(USERS_FILTERS) || {})
};

export const usersFilterSlice = createSlice({
  name: 'filters',
  initialState,
  reducers: {
    setUserFilterItem: (state, action: PayloadAction<OnChange>) => {
      const { name, value } = action.payload;

      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      state.filters[name] = removeSpaces(value);
    },
    resetUsersFilter: state => {
      state.filters = { ...initialState.filters };
    }
  }
});

export const { setUserFilterItem, resetUsersFilter } = usersFilterSlice.actions;

export default usersFilterSlice.reducer;
