import { FC } from 'react';
import { Card, Collapse } from '@shared_medialab/ui_components';
import { useChoosePackageStateContext } from 'providers/ChoosePackage';

const PackagesSidebar: FC = () => {
  // context
  const { selectedPackages } = useChoosePackageStateContext();
  const sports = selectedPackages.flatMap(item => item.sports);

  return (
    <Card header={{ title: 'Group details' }}>
      <Collapse
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        data={sports}
        keys={['regions', 'leagues']}
        maxHeight="calc(100vh - 200px)"
      />
    </Card>
  );
};

export default PackagesSidebar;
