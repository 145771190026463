import React from 'react';
import { Typography, Button } from '@shared_medialab/ui_components';
import { useTranslation } from 'react-i18next';
import { useUIDispatchContext } from 'providers/UI';
import { ModalKeys } from 'providers/UI/types';
import { useEventsSelectedStateContext } from 'providers/Events';
import './index.css';

const { Text } = Typography;

const UnBookSection = () => {
  const { t } = useTranslation('events');
  const { booked } = useEventsSelectedStateContext();
  const { toggleModal } = useUIDispatchContext();

  return (
    <div
      className={`unbook ${
        !booked.length && 'unbook-disable'
      } absolute-position pt--16 flex-display pr--56 flex-justify-end flex-align-items-center`}
    >
      <Text className="mr--12">Selection items {booked?.length}</Text>
      <Button
        flexibility="content-size"
        color="danger"
        size="small"
        icon="c-unbook"
        iconSize={18}
        onClick={() => toggleModal(ModalKeys.unBook, true, { booked })}
        cornerRadius="smooth"
      >
        {t('unbook')}
      </Button>
    </div>
  );
};

export default UnBookSection;
