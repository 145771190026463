import styled from 'styled-components';

export const StyledProjectsAndRoles = styled.div`
  padding: 0 1px 1px 1px;
  width: 100%;
  height: 47.7vh;
  border-radius: 6px;
  overflow-x: auto;
  position: relative;
`;

export const StyledIcon = styled.div`
  display: flex;
  .icon {
    font-weight: bolder;
  }
`;

export const StyledDescription = styled.div`
  padding-top: 20px;
  h5 {
    color: #7574a6;
    font-size: 12px;
    padding-bottom: 6px;
  }
  p {
    font-size: 10px;
    color: #7574a6;
  }
`;
