import { Card } from '@shared_medialab/ui_components';
import { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { useUserFormStateContext } from 'providers/UserForm';
import { useWatch } from 'react-hook-form';
import client from 'apolloClient';
import { ROLE_FRAGMENT } from 'gql/permissions/fragments';
import { RoleFragment } from 'gql/permissions/types/RoleFragment';

import AdminProjectCard from '../ProjectCard';
import { StyledDescription, StyledProjectsAndRoles } from './styled';

const ProjectsRoles: FC = () => {
  // translations
  const { t } = useTranslation();
  // form
  const { control } = useUserFormStateContext();

  const roleId = useWatch({
    control,
    name: 'project.role'
  });

  const role: RoleFragment | null = client.readFragment({
    id: `Role:${roleId}`,
    fragment: ROLE_FRAGMENT
  });

  return (
    <StyledProjectsAndRoles>
      <Card
        header={{
          title: t('users:projects_roles')
        }}
        style={{
          position: 'sticky',
          top: '0',
          zIndex: '999'
        }}
      />
      <AdminProjectCard />
      {role?.description && (
        <StyledDescription>
          <h5>Role description</h5>
          <p>{role.description}</p>
        </StyledDescription>
      )}
    </StyledProjectsAndRoles>
  );
};

export default ProjectsRoles;
