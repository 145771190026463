import { ReactNode } from 'react';
import {
  UserProjects_userProjects_data_admin as adminType,
  UserProjects_userProjects_data_buyer as buyerType,
  UserProjects_userProjects_data_provider as providerType
} from 'gql/organizations/types/UserProjects';

export const ActionTypes = {
  SELECT_PROJECT: 'SELECT_PROJECT'
} as const;

export interface IProjectProps {
  children: ReactNode;
}

export enum ProductStatuses {
  pending = '1',
  trial = '3',
  active = '4',
  inactive = '5',
  integration = '6',
  terminated = '7'
}

export enum ProjectStatuses {
  live = '1',
  archived = '2',
  terminated = '3'
}

export enum BmeStatuses {
  trial = '0',
  deleted = '1',
  live = '2',
  in_active = '3',
  terminated = '4',
  lost = '5'
}

export type Statuses = {
  product?: ProductStatuses[];
  project?: ProjectStatuses[];
  bme?: BmeStatuses[];
};

export type ProjectTypeEnum = adminType | buyerType | providerType;

export type ProjectType = ProjectTypeEnum & {
  status: ProductStatuses;
  projectStatus: ProjectStatuses;
  bmeStatus: BmeStatuses;
};

export type State = {
  selectedProject: ProjectType | null;
};

type SelectProject = {
  type: typeof ActionTypes.SELECT_PROJECT;
  data: ProjectType;
};

export type Action = SelectProject;

export type DispatchContext = {
  selectProject: (id: string) => void;
};

export type Dispatch = (action: Action) => void;
