import { FC, useEffect } from 'react';
import {
  Button,
  Card,
  Input,
  Select,
  Space
} from '@shared_medialab/ui_components';
import Aside from '@shared_medialab/ui_components/build/ui/Aside';
import { useAppDispatch, useAppSelector } from 'store/hooks';
import {
  selectIsUsersResetActive,
  selectUsersFilters
} from 'store/slice/filters/users/selectors';
import { USER_STATUSES } from 'constants/users';
import { setUserFilterItem, resetUsersFilter } from 'store/slice/filters/users';
import { shallowEqual } from 'react-redux';
import { useSidebarStateContext, useUIDispatchContext } from 'providers/UI';
import { SidebarKeys } from 'providers/UI/types';

import RolesSelect from '../RolesSelect';

const FiltersSidebar: FC = () => {
  // context
  const { usersFilters } = useSidebarStateContext();
  const { toggleSidebar } = useUIDispatchContext();
  // redux
  const dispatch = useAppDispatch();

  const filters = useAppSelector(selectUsersFilters(), shallowEqual);

  const isResetActive = useAppSelector(selectIsUsersResetActive());

  useEffect(() => {
    dispatch(resetUsersFilter());
  }, []);

  return (
    <Aside
      isOpen={usersFilters.visible}
      onChange={value => {
        toggleSidebar(SidebarKeys.usersFilters, value);
      }}
    >
      <Card
        header={{ title: 'Filters' }}
        style={{ display: 'flex', flexDirection: 'column', height: '100%' }}
        bodyStyle={{
          flex: 1,
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'space-between'
        }}
      >
        <Space direction="column" size="xl">
          <Input
            label="Search by keyword"
            placeholder="Type name or email"
            labelAppearance="title"
            cornerRadius="smooth"
            value={filters.name}
            onChange={e =>
              dispatch(
                setUserFilterItem({
                  name: 'name',
                  value: e.target.value
                })
              )
            }
          />
          <RolesSelect type="filter" />
          <Select
            label="Status"
            placeholder="Select Status"
            options={Object.keys(USER_STATUSES).map(item => ({
              label: USER_STATUSES[item as keyof typeof USER_STATUSES]?.title,
              value: item,
              badge: USER_STATUSES[item as keyof typeof USER_STATUSES]?.color
            }))}
            hideSearch
            value={filters.status}
            onChange={value => {
              dispatch(
                setUserFilterItem({
                  name: 'status',
                  value
                })
              );
            }}
            cornerRadius="smooth"
            mode="multiple"
          />
        </Space>
        <Button
          cornerRadius="smooth"
          color="contrastNew"
          disabled={!isResetActive}
          onClick={() => {
            dispatch(resetUsersFilter());
          }}
        >
          Reset
        </Button>
      </Card>
    </Aside>
  );
};

export default FiltersSidebar;
