import styled from '@shared_medialab/ui_components';
import { rgba } from 'polished';
import { css } from 'styled-components';

import { IPieBox, IPieItemsProps } from './types';

export const StyledChart = styled.div`
  border-radius: 10px;
  overflow: hidden;
  background-color: var(--primary-bg-1);
`;
export const StyledMapBox = styled.div`
  height: 400px;
`;
export const StyledTooltip = styled.div`
  border: none !important;
  outline: none !important;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: white;
  ${({ theme }) => css`
    width: 250px;
    padding: ${theme.sizes.base};
    border-radius: ${theme.spaces.xl};
    box-shadow: 0.2rem 0.2rem 0.5rem 0.2rem ${rgba(theme.colors.gray[2], 0.15)};
  `}
`;
export const StyledOverview = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
`;
export const StyledTableBox = styled.div<{ size?: string }>`
  height: ${({ size }) => (size === 'big' ? '800px' : '400px')};
  overflow-y: auto;
`;
export const StyledChartsBox = styled.div`
  padding: 10px;
  height: 400px;
`;
export const StyledPieBox = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: flex-start;
`;
export const StyledPie = styled.div`
  width: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  overflow: hidden;
`;
export const StyledPieItemBox = styled.div`
  width: 50%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  overflow: auto;
`;
export const StyledPieItemsBox = styled.div<IPieBox>`
  width: 70%;
  display: flex;
  align-items: center;
`;
export const StyledPieItems = styled.div<IPieItemsProps>`
  border-radius: 25px;
  margin: 10px;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 8px 15px;
  color: ${({ color }) => color};
  background-color: ${({ backgroundColor }) => backgroundColor};
`;
