import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import dayjs from 'dayjs';
import { MatchTypesEnum } from 'constants/match';

import {
  EventsFiltersState,
  OnChange,
  Reset,
  SetError,
  SetLoading,
  SetName,
  SetPagination,
  SetPresetName,
  SetStreamError,
  TogglePreset
} from './types';

const initialFilters = {
  name: '',
  // auto_booking: [],
  dates: {
    startDate: dayjs().startOf('day').format(),
    endDate: dayjs().add(1, 'months').endOf('day').format()
  },
  sportIds: [],
  regionIds: [],
  leagueIds: [],
  status: [],
  bookingType: ''
};

const initialData = {
  selectedPreset: '',
  name: '',
  error: {
    value: false,
    message: ''
  },
  streamUrlError: {
    value: false,
    message: '',
    matchId: ''
  },
  pagination: {
    page: 0,
    total: 0
  },
  loading: true,
  isPlayDisabled: true
};

const initialState: EventsFiltersState = {
  [MatchTypesEnum.list]: {
    filters: {
      ...initialFilters
    },
    ...initialData
  },
  [MatchTypesEnum.booked]: {
    filters: {
      ...initialFilters
    },
    ...initialData
  },
  [MatchTypesEnum.history]: {
    ...initialData,
    ...{
      filters: {
        ...initialFilters,
        ...{
          dates: {
            startDate: dayjs().add(-1, 'week').startOf('day').format(),
            endDate: dayjs().endOf('day').format()
          }
        }
      }
    }
  }
};

export const eventsFilterSlice = createSlice({
  name: 'league-filters',
  initialState,
  reducers: {
    setEventsFilterItem: (state, action: PayloadAction<OnChange>) => {
      const { type, name, value } = action.payload;

      state[type].loading = true;

      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      state[type].filters[name] = value;
    },
    setEventsFilterStatusItem: (state, action: PayloadAction<OnChange>) => {
      const { type, name, value } = action.payload;
      state[type].loading = true;

      const checked = state[type].filters[name] as string[];

      if (!checked.includes(value as string)) {
        checked.push(value as string);
      } else {
        const index = checked.indexOf(value as string);
        checked.splice(index, 1);
      }
    },
    toggleEventsFilterPreset: (state, action: PayloadAction<TogglePreset>) => {
      const { value, type, filters } = action.payload;

      state[type].loading = true;

      if (state[type].selectedPreset === value) {
        state[type].selectedPreset = '';
        state[type].name = '';
        state[type].filters = { ...initialFilters };
      } else {
        state[type].selectedPreset = value;
        state[type].name = value;

        state[type].filters = {
          ...state[type].filters,
          ...filters
        };
      }
    },
    setEventsFilterPresetName: (
      state,
      action: PayloadAction<SetPresetName>
    ) => {
      const { value, type } = action.payload;

      state[type].selectedPreset = value;
    },
    setEventsFilterPresetNameInput: (state, action: PayloadAction<SetName>) => {
      const { type, value } = action.payload;

      state[type].name = value;
      state[type].error = {
        value: false,
        message: ''
      };
    },
    setEventsFilterPresetError: (state, action: PayloadAction<SetError>) => {
      const { type, value, message } = action.payload;

      state[type].error = { value, message };
    },
    resetEventsFilters: (state, action: PayloadAction<Reset>) => {
      const { type } = action.payload;

      switch (type) {
        case MatchTypesEnum.list:
          state[type] = { ...initialState[MatchTypesEnum.list] };
          break;
        case MatchTypesEnum.history:
          state[type] = { ...initialState[MatchTypesEnum.history] };
          break;
        default:
          state[type] = { ...initialState[MatchTypesEnum.list] };
          break;
      }
    },
    setEventsPage: (state, action: PayloadAction<SetPagination>) => {
      const { type, value } = action.payload;

      state[type].pagination.page = value;
      state[type].loading = true;
    },
    setEventsTotal: (state, action: PayloadAction<SetPagination>) => {
      const { type, value } = action.payload;

      state[type].pagination.total = value;
      state[type].loading = false;
    },
    setEventsLoading: (state, action: PayloadAction<SetLoading>) => {
      const { type, value } = action.payload;

      state[type].loading = value;
    },
    setIsPlayDisabled: (state, action: PayloadAction<SetLoading>) => {
      const { type, value } = action.payload;

      state[type].isPlayDisabled = value;
    },
    setStreamUrlError: (state, action: PayloadAction<SetStreamError>) => {
      const { type, value, message, matchId } = action.payload;
      state[type].streamUrlError = { value, message, matchId };
    }
  }
});

export const {
  setEventsFilterItem,
  setEventsFilterStatusItem,
  toggleEventsFilterPreset,
  setEventsFilterPresetName,
  setEventsFilterPresetNameInput,
  setEventsFilterPresetError,
  resetEventsFilters,
  setEventsPage,
  setEventsTotal,
  setEventsLoading,
  setIsPlayDisabled,
  setStreamUrlError
} = eventsFilterSlice.actions;

export default eventsFilterSlice.reducer;
