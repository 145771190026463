import { RootState } from 'store';

import { defaultUsersFilters } from '.';

export const selectUsersFilters = () => (state: RootState) =>
  state.filters.users.filters || defaultUsersFilters;

export const selectIsUsersResetActive = () => (state: RootState) => {
  const { name, role, status } = selectUsersFilters()(state);

  return !!name || !!role.length || !!status.length;
};
