import { FC } from 'react';
import { useParams } from 'react-router-dom';
import { MatchTypesEnum } from 'constants/match';
import * as permissionConstants from 'constants/permissions';

import MatchesGroupTitle from '../MatchesGroupTitle';
import MatchItem from '../MatchItem';
import { IDateSectionProps } from './types';
import UnBookSection from '../MatchItem/components/UnBookSection';
import { RouteParams as EventsRouterParams } from '../Content/types';
import Private from 'components/shared/Private';

const DateSection: FC<IDateSectionProps> = ({
  date,
  group,
  isSectionSelected,
  onClickBulkAction
}) => {
  const { type } = useParams() as EventsRouterParams;

  return (
    <>
      {type === MatchTypesEnum.booked && (
        <Private
          routeId={permissionConstants.BUYER_ROUTES_IDS.booked}
          routeKey={permissionConstants.UNBOOK}
        >
          <UnBookSection />
        </Private>
      )}
      <MatchesGroupTitle
        count={group.length}
        date={`${date}`}
        checked={isSectionSelected}
        action={() => onClickBulkAction(date)}
      />
      {group.map(item => (
        <MatchItem key={`match-item-${item.id}`} id={item.id} />
      ))}
    </>
  );
};

export default DateSection;
