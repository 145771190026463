/* ============================================================
 * UI
 * ============================================================ */
import styled, { css } from 'styled-components';
import { Row, utils } from '@shared_medialab/ui_components';
import { rem, rgba } from 'polished';

import { ITabItemProps } from './types';

export const StyledFooter = styled.div`
  height: 100%;
  padding: 10px;
  display: flex;
  justify-content: flex-end;
`;

export const Container = styled(Row)`
  min-height: 50px;
  position: static;
  overflow: hidden;
  outline: none;
  border-bottom: 1px solid #a0a9ef;
  border-radius: 6px 6px 0px 0px;
  padding: 0;
  ${({ theme }) => css`
    margin-bottom: ${utils.rem(14)};
    background-color: ${theme.colors.light[0]};
  `}
`;

export const RightActions = styled.div`
  display: flex;
  justify-content: flex-end;
  padding-right: 12px;
`;

export const ExportButton = styled.button`
  width: 34px;
  height: 34px;
  border: none;
  display: flex;
  align-items: center;
  justify-content: center;
  transition: color 0.4s, background 0.4s, opacity 0.4s;
  cursor: pointer;
  background: rgba(21, 107, 255, 0.07);
  i {
    color: #156bff;
  }
  ${({ theme }) => css`
    border-radius: ${theme.sizes.xl};
    span {
      color: ${theme.colors.primary};
    }
    &:hover {
      background-color: ${theme.colors.primary};
      span {
        color: ${theme.colors.light[0]};
      }
      i {
        color: #ffffff;
      }
    }
  `}
`;

export const AnimatedButton = styled.button<{ visible: boolean }>`
  width: 35px;
  height: 35px;
  border: none;
  align-items: center;
  justify-content: center;
  animation: waves 1s alternate infinite;
  cursor: pointer;
  ${({ theme, visible }) => css`
    display: ${visible ? 'flex' : 'none'};
    border-radius: ${theme.sizes.xl};
    background-color: ${theme.colors.orange[5]};
    padding: ${rem(4)};
    span {
      color: ${theme.colors.light[0]};
    }
  `}
`;

export const CountBadge = styled.button<{ visible: boolean }>`
  width: 15px;
  height: 15px;
  border: none;
  position: absolute;
  top: 0px;
  right: 0px;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  animation: scale-open-big 0.2s 1 alternate;
  ${({ theme, visible }) => css`
    display: ${visible ? 'flex' : 'none'};
    border-radius: 50%;
    background-color: ${theme.colors.red[5]};
    color: ${theme.colors.white};
    font-size: ${theme.sizes.xs};
  `}
`;

export const FlexContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-end;
`;

export const TabItem = styled.button<ITabItemProps>`
  background-color: transparent;
  border: none;
  font-weight: 600;
  position: relative;
  cursor: pointer;
  transition: all 0.3s;
  white-space: nowrap;
  display: flex;
  align-items: center;
  text-align: center;
  justify-content: center;
  ${({ theme, active, disabled }) => {
    return css`
      gap: ${theme.spaces.sm};
      margin: 0 ${theme.spaces.lg};
      padding: ${theme.spaces.lg} 0;
      color: ${rgba(theme.colors.text, 0.6)};
      &::after {
        content: '';
        position: absolute;
        bottom: 0;
        left: 0;
        right: 0;
        height: 0;
        transition: 0.2s;
        background-color: var(--primary);
        border-radius: ${theme.sizes.xs} ${theme.sizes.xs} 0 0;
        height: ${active ? 4 : 0}px;
      }
      &:hover {
        color: var(--primary);
      }
      ${active && `color: var(--primary)`};
      ${disabled &&
      css`
        opacity: 0.7;
        cursor: default;
        &:hover {
          color: ${rgba(theme.colors.text, 0.6)};
        }
      `}
    `;
  }}
`;
