/* eslint-disable @typescript-eslint/no-unused-vars */
import { FC, useState } from 'react';
import {
  Col,
  Row,
  BookingCard,
  Space,
  Card,
  Loading,
  NoData,
  Pagination,
  Screen
} from '@shared_medialab/ui_components';
import { useQuery } from '@apollo/client';
import { ContentPackages } from 'gql/contentPackages/types/ContentPackages';
import { GET_CONTENT_PACKAGES } from 'gql/contentPackages/queries';
import {
  useChoosePackageDispatchContext,
  useChoosePackageStateContext
} from 'providers/ChoosePackage';
import { CollapseDataType } from '@shared_medialab/ui_components/build/base/Collapse/types';
import { useNavigate, useParams } from 'react-router-dom';
import { PackageSports } from 'providers/ChoosePackage/types';
import { SidebarKeys } from 'providers/UI/types';
import { useSidebarStateContext } from 'providers/UI';

import { Content } from './styled';
import { PackagesSidebar } from './components';

const limit = 10;

const ChoosePackage: FC = () => {
  // context
  const { choosePackages, choosePackagesSports } =
    useChoosePackageDispatchContext();

  const { selectedPackages } = useChoosePackageStateContext();

  const {
    [SidebarKeys.selectedPackages]: { visible: isOpen }
  } = useSidebarStateContext();

  // navigation
  const { id } = useParams();
  const navigate = useNavigate();
  // states
  const [page, setPage] = useState(0);
  // graphql
  const { data: contentPackagesData, loading } = useQuery<ContentPackages>(
    GET_CONTENT_PACKAGES,
    {
      variables: {
        query: {
          pagination: {
            page,
            limit: limit
          },
          visible: true
        }
      },
      fetchPolicy: 'no-cache'
    }
  );

  const results = contentPackagesData?.contentPackages?.data.results || [];
  const total = contentPackagesData?.contentPackages?.data.total || 0;

  const onClickDone = () => {
    const values: PackageSports = {};
    const sportIds: string[] = [];

    const getIds = (children: CollapseDataType[], parentId: string) => {
      children.forEach(item => {
        const nested = Object.values(item).find(value =>
          Array.isArray(value)
        ) as CollapseDataType[];

        const key = item.creationId || item.id;

        if (!parentId && key) {
          if (!values[key]) {
            values[key] = [];
          }

          sportIds.push(item.id);
        }

        if (nested) {
          return getIds(nested, parentId || key || '');
        }

        values[parentId].push(key || '');
      });
    };

    const data = selectedPackages.flatMap(item => item.sports);

    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    getIds(data, '');

    if (Object.keys(values).length !== 0) {
      choosePackagesSports(values, sportIds);
      id
        ? navigate(`/autobooking/details/${id}`)
        : navigate(`/autobooking/details`);
    }
  };

  return (
    <Screen
      fullWidth
      header={{
        title: 'Choose package',
        onBack: () => {
          id
            ? navigate(`/autobooking/details/${id}`)
            : navigate(`/autobooking/details`);
        }
      }}
      footer={{
        button: {
          title: 'Done',
          color: 'confirmNew',
          flexibility: 'biggest-width',
          cornerRadius: 'smooth',
          onClick: onClickDone
        }
      }}
      isSidebarOpen={isOpen}
      sidebar={<PackagesSidebar />}
    >
      <Content>
        <Row gutter={[8, 8]} wrap>
          {loading ? (
            <Loading />
          ) : !results.length ? (
            <NoData transparent={true} size="default" />
          ) : (
            results.map(item => {
              const isSelected = !!selectedPackages.find(
                packages => packages.id === item.id
              );

              return (
                <Col span={6} key={`package-item-${item.id}`}>
                  <BookingCard
                    counts={[item.sportsCount, item.leaguesCount]}
                    title={item.name}
                    type="checkbox"
                    onClick={() => choosePackages(item)}
                    onAction={() => choosePackages(item)}
                    status={isSelected ? 'active' : 'default'}
                  />
                </Col>
              );
            })
          )}
          {total + 1 > 15 && (
            <Space justify="center" align="center">
              <Pagination
                total={total}
                page={page + 1}
                onChange={p => setPage(p - 1)}
              />
            </Space>
          )}
        </Row>
      </Content>
    </Screen>
  );
};

export default ChoosePackage;
