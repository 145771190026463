import { FC, memo, useState } from 'react';
import { Button, Icon, Tab } from '@shared_medialab/ui_components';
import { useAppDispatch, useAppSelector } from 'store/hooks';
import { toggleEventsFilterPreset } from 'store/slice/filters';
import { selectSelectedPresetName } from 'store/slice/filters/events/selector';
import { useParams } from 'react-router-dom';

import { IconButton, StyledPreset, StyledWrapper } from './styled';
import { IPresetsTabProps } from './types';
import { RouteParams } from '../Content/types';
import useEventsFilters from 'hooks/useEventsFilter';

const PresetsTab: FC<IPresetsTabProps> = ({ id, changeToFilters }) => {
  // navigation
  const { type } = useParams() as RouteParams;
  // hooks
  const {
    presetFilters,
    presetFiltersNames,
    onDeleteFilters,
    onDeleteAllFilters
  } = useEventsFilters();

  // redux
  const dispatch = useAppDispatch();
  const selectedPreset = useAppSelector(selectSelectedPresetName(type));
  // states
  const [deleting, setDeleting] = useState('');

  const getCount = (id: string) => {
    const item = presetFilters[id];
    let count = 0;

    Object.values(item).forEach(filter => {
      if (Array.isArray(filter) && filter.length) {
        count++;
      }
    });

    return count;
  };

  return (
    <Tab id={id} text="List's Presets">
      <StyledWrapper>
        <div>
          {presetFiltersNames.map(name => {
            const count = getCount(name);

            return (
              <StyledPreset
                key={`preset-item-${name}`}
                isActive={name === selectedPreset}
                isDeleting={name === deleting}
                onClick={() => {
                  dispatch(
                    toggleEventsFilterPreset({
                      value: name,
                      type,
                      filters: presetFilters[name]
                    })
                  );

                  if (selectedPreset !== name) {
                    changeToFilters();
                  }
                }}
              >
                <h6>
                  {name}
                  <IconButton
                    onMouseEnter={() => setDeleting(name)}
                    onMouseLeave={() => setDeleting('')}
                    onClick={e => {
                      e.stopPropagation();
                      onDeleteFilters(name);
                    }}
                  >
                    <Icon type="delete" color="var(--dark-gray)" />
                  </IconButton>
                </h6>
                <p>
                  Includes {count} selected filter{count > 1 ? 's' : ''}
                </p>
              </StyledPreset>
            );
          })}
        </div>

        <Button
          cornerRadius="smooth"
          color="contrastNew"
          onClick={() => {
            onDeleteAllFilters(() => {
              changeToFilters();
            });
          }}
        >
          Delete all
        </Button>
      </StyledWrapper>
    </Tab>
  );
};

export default memo(PresetsTab);
