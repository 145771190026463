import { useLazyQuery, useQuery } from '@apollo/client';
import {
  Input,
  Select,
  Space,
  Checkbox,
  Card,
  DatePickerNew
} from '@shared_medialab/ui_components';
import { CHECK_AUTOBOOKING_NAME } from 'gql/contentPackages/queries';
import { CheckAutobookingName } from 'gql/contentPackages/types/CheckAutobookingName';
import { GET_ALL_COUNTRIES } from 'gql/countries/queries';
import { AllCountries } from 'gql/countries/types/AllCountries';
import {
  useAutoBookingSequenceContext,
  useAutoBookingStateContext
} from 'providers/AutoBooking';
import { FC, useMemo, useState } from 'react';
import { Controller, useWatch } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useProjectStateContext } from 'providers/Projects';
import { PACKAGE_LABELS } from 'constants/autobooking';

import InfoLabel from '../InfoLabel';

const Settings: FC = () => {
  // states
  const [search, setSearch] = useState('');
  // translations
  const { t } = useTranslation(['auto_booking', 'messages']);
  // context
  const { control, setError, clearErrors } = useAutoBookingStateContext();

  const sequence = useAutoBookingSequenceContext();

  const parentRule = useWatch({
    control,
    name: 'parentRule'
  });

  // hooks
  const minEndDate = useWatch({
    control,
    name: 'startDate'
  });

  // graphql
  const variables = useMemo(
    () => ({
      name: search
    }),
    [search]
  );

  const { data } = useQuery<AllCountries>(GET_ALL_COUNTRIES, {
    fetchPolicy: 'cache-first',
    nextFetchPolicy: 'cache-only',
    variables: {
      query: variables
    },
    context: {
      debounceKey: 'countries',
      debounceTimeout: 400
    }
  });

  const [validateUniqueName] = useLazyQuery<CheckAutobookingName>(
    CHECK_AUTOBOOKING_NAME,
    {
      onCompleted(data) {
        if (data.checkAutobookingName?.data) {
          setError('name', {
            message: t('messages:name_error')
          });
        } else {
          clearErrors(['name']);
        }
      }
    }
  );

  const isIndefinite = useWatch({
    control,
    name: 'indefinite'
  });

  const parentCountries = parentRule.countries;
  const countriesFixed =
    data?.allCountries?.data.filter(
      elem => parentCountries && parentCountries.includes(elem.id)
    ) || [];

  const countriesPpm = data?.allCountries?.data || [];
  const countries =
    PACKAGE_LABELS.auto === parentRule.type ? countriesFixed : countriesPpm;

  const { selectedProject } = useProjectStateContext();

  return (
    <div>
      <Card header={{ title: 'Rule settings' }} />
      <Space
        direction="column"
        size="sm"
        style={{
          height: 'calc(100vh - 190px)'
        }}
      >
        <Card>
          <Space direction="column" size="base">
            <Controller
              render={({
                field: { value, onChange },
                fieldState: { error }
              }) => {
                return (
                  <Input
                    label={t('name')}
                    placeholder={t('type_name')}
                    cornerRadius="smooth"
                    value={value}
                    onChange={onChange}
                    disabled={parentRule.type === PACKAGE_LABELS.auto}
                    onBlur={e => {
                      validateUniqueName({
                        variables: {
                          query: {
                            name: e.target.value,
                            organizationId: selectedProject?.id
                          }
                        }
                      });
                    }}
                    errorText={error?.message}
                  />
                );
              }}
              name="name"
              control={control}
              rules={{
                required: {
                  value: true,
                  message: t('messages:field_required')
                }
              }}
            />
            <div>
              <InfoLabel
                title={t('duration')}
                tooltip={t('info_tooltip')}
                width={180}
              />
              <Controller
                render={({ field: { onChange, value } }) => {
                  return (
                    <Checkbox
                      onChange={onChange}
                      checked={value}
                      label={t('indefinite')}
                    />
                  );
                }}
                name="indefinite"
                control={control}
              />
            </div>
            <Space>
              <Controller
                render={({ field: { onChange, value } }) => {
                  return (
                    <DatePickerNew
                      key={`${value}`}
                      title={t('start_date')}
                      date={value}
                      disabled={isIndefinite}
                      onChange={e => onChange(e)}
                      minDate={new Date()}
                    />
                  );
                }}
                name="startDate"
                control={control}
              />
              <Controller
                render={({ field: { onChange, value } }) => {
                  return (
                    <DatePickerNew
                      key={`${value}`}
                      title={t('end_date')}
                      date={value}
                      disabled={isIndefinite}
                      onChange={e => onChange(e)}
                      minDate={minEndDate}
                    />
                  );
                }}
                name="endDate"
                control={control}
              />
            </Space>
          </Space>
        </Card>
        <Card>
          <Space
            direction="column"
            size="sm"
            style={{
              minHeight: 'calc(100vh - 425px)'
            }}
          >
            <InfoLabel
              title={t('booking_countries')}
              tooltip={t('booking_countries_tooltip')}
              width={240}
            />
            <Controller
              render={({ field: { onChange, value } }) => {
                return (
                  <Select
                    options={countries?.map(item => ({
                      value: item.id,
                      label: item.name
                    }))}
                    searchValue={search}
                    onSearch={e => setSearch(e.target.value)}
                    onChange={v => {
                      //Todo: delete after Autobooking testing
                      // setValue('sport', '');
                      // setValue('selectedSports', {});
                      // setValue('selectedSportsCheck', {});
                      // setValue('selectedSportsCount', {});
                      // setValue('selectedRegionsOnSport', []);
                      // setValue('selectedRegionsOnLeagues', []);
                      // setValue('completelyRegions', []);
                      // setValue('isAll', false);
                      onChange(v);
                    }}
                    placeholder={t('select_country')}
                    value={value}
                    mode="multiple"
                    cornerRadius="smooth"
                    disabled={sequence.countriesIsDisable}
                  />
                );
              }}
              name="countries"
              control={control}
            />
          </Space>
        </Card>
      </Space>
    </div>
  );
};

export default Settings;
