import styled from '@shared_medialab/ui_components';
import { rgba } from 'polished';
import { css } from 'styled-components';

import {
  IDateWrapperProps,
  IExportBoxProps,
  IExportButProps,
  IIconProps,
  ISwitchIconsProps
} from './types';

export const StyledChartHeader = styled.div`
  position: relative;
  ${({ theme }) => css`
    background-color: var(--primary-bg-1);
    padding: ${theme.sizes.xs} ${theme.sizes.base};
    border-bottom: 1px solid ${rgba(theme.colors.gray[5], 0.1)};
  `}
`;
export const StyledTest = styled.div`
  position: relative;
  ${({ theme }) => css`
    padding: ${theme.spaces.xs};
  `}
`;
export const StyledSwitch = styled.div<ISwitchIconsProps>`
  height: 30px;
  width: ${({ isType }) => (isType === 'threePage' ? '95px' : '65px')};
  cursor: pointer;
  ${({ theme }) => css`
    background-color: var(--primary-bg-6);
    border-radius: ${theme.spaces.lg};
    padding: ${theme.spaces.xs};
  `}
  &:after {
    transition: 0.4s;
    content: '';
    position: absolute;
    top: 2px;
    ${({ movement }) => css`
      right: ${movement === 'table' && '4px'} ${movement === 'chart' && '35px'}
        ${movement === 'three' && '65px'};
    `}
    width: 34px;
    height: 34px;
    z-index: 0;
    ${({ theme }) => css`
      border-radius: ${theme.spaces.lg};
      background-color: ${rgba(theme.colors.orange[5], 1)};
    `}
  }
`;

export const StyledSwitchIcon = styled.div<IIconProps>`
  position: absolute;
  ${({ position }) => css`
    right: ${position === 'left' && '7px'} ${position === 'three' && '70px'}
      ${position === 'right' && '40px'};
    top: ${position === 'left' && '7px'} ${position === 'right' && '7px'}
      ${position === 'three' && '6px'};
  `}
  z-index: 2;
`;

export const StyledDateButton = styled.div`
  margin-left: 15px;
  ${({ theme }) => css`
    padding: ${theme.spaces.base};
  `}
`;

export const StyledDateWrapper = styled.div<IDateWrapperProps>`
  cursor: pointer;
  outline: 0;
  background-color: transparent;
  width: 260px;
  border-radius: 6px;
  border: 1px solid transparent;
  transition: all 0.2s linear;
  ${({ hover }) => css`
    border: ${hover && '1px solid var(--primary-1)'};
  `}
`;
export const StylesExportBut = styled.button<IExportButProps>`
  width: 25px;
  height: 25px;
  cursor: pointer;
  outline: 0;
  border: none;
  opacity: ${({ show }) => (show ? 1 : 0)};
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 6px;
  border: 1px solid var(--primary-bg-1);
  padding: 15px;
  transition: 0.2s;
  z-index: 1111;
  margin-right: 5px;
  ${({ backgroundColor }) => css`
    background-color: ${!backgroundColor
      ? 'var(--primary-bg-3)'
      : 'var(--primary-1)'};
  `}
`;
export const StyledIconsBox = styled.div`
  display: flex;
  justify-content: space-around;
  align-items: center;
`;
export const StyledExportBox = styled.div<IExportBoxProps>`
  width: 55px;
  height: 35px;
  display: flex;
  justify-content: space-around;
  align-items: center;
  border-radius: 15px;
  position: absolute;
  top: ${({ show }) => (!show ? '35px' : '47px')};
  left: center;
  opacity: ${({ show }) => (!show ? 0 : 1)};
  ${({ theme }) => css`
    background-color: ${rgba(theme.colors.white, 0.8)};
    box-shadow: 0.2rem 0.2rem 0.5rem 0.2rem ${rgba(theme.colors.black, 0.15)};
    color: black;
  `}
  transition: 0.4s;
`;
