import { FC, useMemo } from 'react';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import { FilledTabs, Icon } from '@shared_medialab/ui_components';
import { useStatusState, usePermission } from 'hooks';
import {
  BmeStatuses,
  ProductStatuses,
  ProjectStatuses
} from 'providers/Projects/types';
import { useTranslation } from 'react-i18next';
import * as permissionConstants from 'constants/permissions';
import { GET_USER_PROJECTS } from 'gql/organizations/queries';
import { useQuery } from '@apollo/client';
import { useProjectStateContext } from 'providers/Projects';

import { SelectProject, UserInfo } from './components';
import { StyledHeader } from './styled';
import StatusButton from './components/StatusButton';
import ShoppingCart from './components/ShoppingCart';

const Header: FC = () => {
  // hooks
  const { checkUserPermission } = usePermission();
  // translations
  const { t } = useTranslation('header');
  // navigation
  const navigate = useNavigate();
  const location = useLocation();
  // graphql
  const { data } = useQuery(GET_USER_PROJECTS, {
    fetchPolicy: 'cache-only'
  });

  // context
  const { selectedProject } = useProjectStateContext();
  const hasProjects = !!data?.userProjects?.data?.buyer.length;

  // context
  const isUsersAllowed = useStatusState({
    product: [
      ProductStatuses.active,
      ProductStatuses.pending,
      ProductStatuses.integration,
      ProductStatuses.trial
    ]
  });

  const reportAndHistory = useStatusState({
    product: [ProductStatuses.inactive],
    bme: [
      BmeStatuses.live,
      BmeStatuses.trial,
      BmeStatuses.terminated,
      BmeStatuses.lost,
      BmeStatuses.in_active
    ],
    project: [ProjectStatuses.terminated]
  });

  const onlyList = useStatusState({
    product: [ProductStatuses.pending],
    bme: [BmeStatuses.live, BmeStatuses.trial],
    project: [ProjectStatuses.live]
  });

  const nothingIsAvailable =
    useStatusState({
      product: [ProductStatuses.trial],
      bme: [BmeStatuses.live],
      project: [ProjectStatuses.live]
    }) && selectedProject?.trialCount === '0';

  const tabs = useMemo(
    () => [
      {
        id: '/streaming/events',
        name: 'events',
        permissions: [
          permissionConstants.BUYER_ROUTES_IDS.list,
          permissionConstants.BUYER_ROUTES_IDS.booked,
          permissionConstants.BUYER_ROUTES_IDS.history,
          permissionConstants.BUYER_ROUTES_IDS.autoBooking
        ],
        routeKey: permissionConstants.VIEW,
        disabled: nothingIsAvailable
      },
      {
        id: '/streaming/reporting',
        name: 'reports',
        permissions: [permissionConstants.BUYER_ROUTES_IDS.reports],
        routeKey: permissionConstants.VIEW,
        disabled: onlyList || nothingIsAvailable
      },
      {
        id: '/streaming/users',
        name: 'users',
        disabled:
          !isUsersAllowed || reportAndHistory || onlyList || nothingIsAvailable,
        permissions: [permissionConstants.BUYER_ROUTES_IDS.buyer_users],
        routeKey: permissionConstants.VIEW
      }
    ],
    [isUsersAllowed, nothingIsAvailable, onlyList, reportAndHistory]
  );

  const activeTab = useMemo(() => {
    switch (location.pathname) {
      case '/streaming/events/list':
      case '/streaming/events/booked':
      case '/streaming/events/history':
      case '/streaming/events/autobooking':
        return '/streaming/events';
      default:
        return location.pathname;
    }
  }, [location.pathname]);

  const homePagePath =
    localStorage.getItem('lastRedirect') || '/streaming/events/list';

  return (
    <StyledHeader className="flex-display ph--24 flex-align-items-center flex-justify-space-between">
      <div className="flex-display flex-align-items-center pv--4 flex-1">
        <div className="pr--24">
          <Link to={homePagePath} className="flex-display">
            <Icon
              className="logo-friendship mr--24"
              type="c-friendship-logo-new"
            />
          </Link>
        </div>
        <div className="mr--8">
          <SelectProject placeholder={t('events:buyer')} />
        </div>
        {hasProjects && <StatusButton />}
      </div>
      <div className="flex-display flex-1 flex-justify-center">
        <FilledTabs
          tabs={tabs.filter(item =>
            item.permissions?.some(p => {
              return checkUserPermission(p, item.routeKey);
            })
          )}
          active={activeTab}
          onChange={id => navigate(String(id))}
          tabType="primary"
          inSubHeader
        />
      </div>
      <div className="flex-display flex-align-items-center flex-1 flex-justify-end">
        {hasProjects && (
          <div className="mr--12">
            <ShoppingCart />
          </div>
        )}
        <UserInfo />
      </div>
    </StyledHeader>
  );
};

export default Header;
