import { FC } from 'react';
import { Checkbox, Typography } from '@shared_medialab/ui_components';
import { setEventsFilterStatusItem } from 'store/slice/filters';
import { useParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { MATCH_STATUSES_VALUES, MatchTypesEnum } from 'constants/match';
import { useAppDispatch, useAppSelector } from 'store/hooks';
import { selectEventsFilters } from 'store/slice/filters/events/selector';

import { RouteParams } from '../Content/types';

const FilterEventsSidebarCheckbox: FC = () => {
  const { type } = useParams() as RouteParams;

  // translations
  const { t } = useTranslation('events');
  // redux
  const dispatch = useAppDispatch();
  const eventsFilters = useAppSelector(selectEventsFilters(type));

  const { Text } = Typography;

  return (
    <>
      <Text level={13} color="var(--text)">
        {t('stream_status')}
      </Text>
      {type === MatchTypesEnum.history ? (
        <div className="flex-display flex-align-items-center">
          <Checkbox
            className="mr--32"
            label={t(`statuses.${MATCH_STATUSES_VALUES.finished}`)}
            onChange={() => {
              dispatch(
                setEventsFilterStatusItem({
                  name: 'status',
                  value: MATCH_STATUSES_VALUES.finished.toString(),
                  type
                })
              );
            }}
            checked={eventsFilters.status.includes(
              MATCH_STATUSES_VALUES.finished.toString()
            )}
          />
          <Checkbox
            label={t(`statuses.${MATCH_STATUSES_VALUES.canceled}`)}
            onChange={() => {
              dispatch(
                setEventsFilterStatusItem({
                  name: 'status',
                  value: MATCH_STATUSES_VALUES.canceled.toString(),
                  type
                })
              );
            }}
            checked={eventsFilters.status.includes(
              MATCH_STATUSES_VALUES.canceled.toString()
            )}
          />
        </div>
      ) : (
        <div className="flex-display flex-align-items-center">
          <Checkbox
            className="mr--32"
            label={t(`statuses.${MATCH_STATUSES_VALUES.not_started}`)}
            onChange={() =>
              dispatch(
                setEventsFilterStatusItem({
                  name: 'status',
                  value: MATCH_STATUSES_VALUES.not_started.toString(),
                  type
                })
              )
            }
            checked={eventsFilters.status.includes(
              MATCH_STATUSES_VALUES.not_started.toString()
            )}
          />
          <Checkbox
            label={t(`statuses.${MATCH_STATUSES_VALUES.started}`)}
            onChange={() => {
              dispatch(
                setEventsFilterStatusItem({
                  name: 'status',
                  value: MATCH_STATUSES_VALUES.started.toString(),
                  type
                })
              );
            }}
            checked={eventsFilters.status.includes(
              MATCH_STATUSES_VALUES.started.toString()
            )}
          />
        </div>
      )}
    </>
  );
};

export default FilterEventsSidebarCheckbox;
