import { FC, memo, useEffect, useState } from 'react';
import {
  Select,
  Space,
  Aside,
  Input,
  Tabs,
  Tab,
  Button,
  DateRangePickerNew
} from '@shared_medialab/ui_components';
import { useTranslation } from 'react-i18next';
import { SidebarKeys } from 'providers/UI/types';
import { useSidebarStateContext, useUIDispatchContext } from 'providers/UI';
import dayjs from 'dayjs';
import { useRegions, useSports } from 'hooks';
import { useAppDispatch, useAppSelector } from 'store/hooks';
import {
  selectEventsFilters,
  selectHasFilters
} from 'store/slice/filters/events/selector';
import { resetEventsFilters, setEventsFilterItem } from 'store/slice/filters';
import { useParams } from 'react-router-dom';
import { MatchTypesEnum } from 'constants/match';

import useLeagues from 'hooks/useLeagues';
import SavePreset from '../SavePreset';
import { StyledFilters, StyledPresetDiv } from './styled';
import PresetsTab from '../PresetsTab';
import { RouteParams } from '../Content/types';
import useEventsFilters from 'hooks/useEventsFilter';
import { getStaticRangesArray } from 'utils/dates';
import FilterEventsSidebarCheckbox from '../FilterEventsSidebarCheckbox';

const FILTERS_ID = 'filters-tab';
const PRESETS_ID = 'presets-tab';

const ListFiltersSidebar: FC = () => {
  const { type } = useParams() as RouteParams;
  // translations
  const { t } = useTranslation('events');
  // hooks
  const { presetFiltersNames } = useEventsFilters();
  // context
  const { toggleSidebar } = useUIDispatchContext();
  const {
    [SidebarKeys['filters']]: { visible: isOpen }
  } = useSidebarStateContext();

  // redux
  const dispatch = useAppDispatch();
  const eventsFilters = useAppSelector(selectEventsFilters(type));
  const hasFilters = useAppSelector(selectHasFilters(type));
  // states
  const [selectedTab, setSelectedTab] = useState(FILTERS_ID);
  const [dateRange, setDateRange] = useState<[Date | null, Date | null]>([
    dayjs(eventsFilters.dates.startDate).local().toDate(),
    dayjs(eventsFilters.dates.endDate).toDate()
  ]);

  const { sports, onSportsScroll, sportSearch, onSportSearch } = useSports();
  const { regions, regionSearch, onRegionSearch } = useRegions({
    sportIds: eventsFilters.sportIds
  });

  const {
    leagues,
    onLeaguesScroll,
    leagueSearch,
    onLeagueSearch,
    leaguesTotal
  } = useLeagues({
    sportIds: eventsFilters.sportIds,
    regionIds: eventsFilters.regionIds
  });

  useEffect(() => {
    dispatch(resetEventsFilters({ type }));
  }, []);

  useEffect(() => {
    if (!presetFiltersNames.length) {
      setSelectedTab(FILTERS_ID);
    }
  }, [presetFiltersNames.length]);

  useEffect(() => {
    setDateRange([
      dayjs(eventsFilters.dates.startDate).local().toDate(),
      dayjs(eventsFilters.dates.endDate).toDate()
    ]);
  }, [dayjs, eventsFilters.dates]);

  return (
    <Aside
      isOpen={isOpen}
      onChange={val => {
        toggleSidebar(SidebarKeys.filters, val);
      }}
    >
      <StyledFilters>
        <Tabs
          color="primary"
          selectedTabId={selectedTab}
          onChange={e => setSelectedTab(`${e}`)}
        >
          <Tab id={FILTERS_ID} text="Filter">
            <div>
              <Space direction="column" size="xl">
                <Input
                  label={t('search')}
                  placeholder={t('type_team_name')}
                  labelAppearance="title"
                  cornerRadius="smooth"
                  value={eventsFilters.name}
                  onChange={e => {
                    const value = e.target.value;

                    // Only dispatch if the value does not start with a space
                    if (value === '' || value[0] !== ' ') {
                      dispatch(
                        setEventsFilterItem({
                          name: 'name',
                          value: value.trimStart(),
                          type
                        })
                      );
                    }
                  }}
                />
                {/* <Select
            label={t('autobooking_rule')}
            placeholder={t('select_rule')}
            options={[]}
            onChange={value => {
              setFilterItem({
                module: FilterModules.eventsFilters,
                name: 'auto_booking',
                data: value
              });
            }}
            onScroll={() => null}
            onSearch={() => null}
            searchValue={''}
            cornerRadius="smooth"
            mode="multiple"
          /> */}
                <DateRangePickerNew
                  title={t('dates')}
                  key={`${dateRange[0]}-${dateRange[1]}`}
                  dateRange={dateRange}
                  staticRanges={[
                    {
                      label: t('dates:today'),
                      value: getStaticRangesArray('day')
                    },
                    {
                      label: t('dates:this_week'),
                      value: getStaticRangesArray('week')
                    },
                    {
                      label: t('dates:this_month'),
                      value: getStaticRangesArray('month')
                    },
                    {
                      label: t('dates:this_year'),
                      value: getStaticRangesArray('year')
                    }
                  ]}
                  onChange={value => {
                    const newDateRange: [Date | null, Date | null] = [
                      dayjs(value.startDate).local().toDate(),
                      dayjs(value.endDate).toDate()
                    ];

                    setDateRange(newDateRange);

                    dispatch(
                      setEventsFilterItem({
                        name: 'dates',
                        value: {
                          startDate: dayjs(value.startDate).format(),
                          endDate: dayjs(value.endDate).format()
                        },
                        type
                      })
                    );
                  }}
                  minDate={
                    type !== MatchTypesEnum.history
                      ? dayjs().add(-1, 'hour').toDate()
                      : undefined
                  }
                  maxDate={
                    type !== MatchTypesEnum.history
                      ? dayjs().add(1, 'month').toDate()
                      : undefined
                  }
                />
                <Select
                  label={t('sports')}
                  placeholder={t('select_sports')}
                  options={sports.map(
                    (item: {
                      id: string;
                      creationId: string;
                      name: string;
                      leaguesCount: string | number | null;
                    }) => ({
                      label: item.name,
                      value: item.creationId
                    })
                  )}
                  value={eventsFilters.sportIds}
                  onClose={() => {
                    if (!eventsFilters.sportIds.length) onSportSearch('');
                  }}
                  onChange={value => {
                    dispatch(
                      setEventsFilterItem({
                        name: 'sportIds',
                        value,
                        type
                      })
                    );
                  }}
                  onScroll={onSportsScroll}
                  onSearch={e => onSportSearch(e.target.value)}
                  searchValue={sportSearch}
                  cornerRadius="smooth"
                  mode="multiple"
                />
                <Select
                  label={t('region')}
                  placeholder={t('select_regions')}
                  options={regions.map(item => ({
                    label: item.name,
                    value: item.creationId
                  }))}
                  value={eventsFilters.regionIds}
                  onChange={value => {
                    dispatch(
                      setEventsFilterItem({
                        name: 'regionIds',
                        value,
                        type
                      })
                    );
                  }}
                  onClose={() => {
                    if (!eventsFilters.regionIds.length) onRegionSearch('');
                  }}
                  onSearch={e => onRegionSearch(e.target.value)}
                  searchValue={regionSearch}
                  cornerRadius="smooth"
                  mode="multiple"
                />
                <Select
                  label={t('leagues')}
                  showSelectAll={false}
                  total={leaguesTotal}
                  placeholder={t('select_leagues')}
                  options={leagues.map(item => ({
                    label: item.name,
                    value: item.creationId,
                    subTitle: `${item?.regionName} - ID: ${item.creationId}`,
                    icon: String(item?.sportName)
                  }))}
                  value={eventsFilters.leagueIds}
                  onChange={value => {
                    dispatch(
                      setEventsFilterItem({
                        name: 'leagueIds',
                        value,
                        type
                      })
                    );
                  }}
                  onClose={() => {
                    if (!eventsFilters.leagueIds.length) onLeagueSearch('');
                  }}
                  onScroll={onLeaguesScroll}
                  onSearch={e => onLeagueSearch(e.target.value)}
                  searchValue={leagueSearch}
                  cornerRadius="smooth"
                  mode="multiple"
                />
                <FilterEventsSidebarCheckbox />
              </Space>
              <StyledPresetDiv>
                <SavePreset
                  changeToPresets={() => setSelectedTab(PRESETS_ID)}
                />
                <Button
                  cornerRadius="smooth"
                  color="contrastNew"
                  disabled={!hasFilters}
                  onClick={() => {
                    dispatch(resetEventsFilters({ type }));
                  }}
                >
                  Reset
                </Button>
              </StyledPresetDiv>
            </div>
          </Tab>
          {!!presetFiltersNames.length && (
            <Tab id={PRESETS_ID} text="List's Presets">
              <PresetsTab
                changeToFilters={() => setSelectedTab(FILTERS_ID)}
                id={PRESETS_ID}
              />
            </Tab>
          )}
        </Tabs>
      </StyledFilters>
    </Aside>
  );
};

export default memo(ListFiltersSidebar);
