import { FC, memo } from 'react';
import { useTranslation } from 'react-i18next';

import { DemoErrorText } from './styled';

const ErrorPreview: FC = () => {
  // translations
  const { t } = useTranslation(['events']);

  return (
    <DemoErrorText>
      <div>{t('events:stream_demo1')}</div>
      <div>{t('events:stream_demo2')}</div>
    </DemoErrorText>
  );
};

export default memo(ErrorPreview);
