import { FC, memo, useCallback, useEffect, useRef, useState } from 'react';
import {
  DateRangePickerNew,
  Icon,
  Space
} from '@shared_medialab/ui_components';
import { useColors, useDayjs } from 'hooks';
import { CSVLink } from 'react-csv';
import { useTranslation } from 'react-i18next';
import * as permissionConstants from 'constants/permissions';

import { IChartsHeaderProps } from './types';
import {
  StyledChartHeader,
  StyledDateButton,
  StyledDateWrapper,
  StyledExportBox,
  StyledIconsBox,
  StyledSwitch,
  StyledSwitchIcon,
  StyledTest,
  StylesExportBut
} from './styled';
import { getStaticRangesArray } from 'utils/dates';
import Private from 'components/shared/Private';
// import TestData from '../../Components/date';

const ChartsHeader: FC<IChartsHeaderProps> = ({
  setItem,
  dateItem,
  title,
  icons,
  setChangePage,
  type,
  date,
  exportData,
  dataT,
  changePage
}) => {
  // hooks
  const { dayjs } = useDayjs();
  const colors = useColors();
  const [isSwitch, setSwitch] = useState(changePage);
  const [exportIconColor, setExportIconColor] = useState(false);
  const [calendarColor, setCalendarColor] = useState(false);
  const [open, setOpen] = useState(false);
  const [range, setRange] = useState<{
    startDate: string | Date;
    endDate: string | Date;
  }>({
    startDate: new Date(),
    endDate: new Date()
  });

  useEffect(() => {
    if (dateItem?.value.startDate.length) {
      setRange({
        startDate: dateItem?.value.startDate as string,
        endDate: dateItem?.value.endDate as string
      });
    }
  }, [dateItem?.value.endDate, dateItem?.value.startDate]);

  const { t } = useTranslation(['sidebar', 'events']);

  const headersForMapData = [
    { label: 'Id', key: 'id' },
    { label: 'Stream Name', key: 'streamName' },
    { label: 'Count', key: 'count' }
  ];

  const headersForPieData = [
    { label: 'Id', key: 'id' },
    { label: 'Sport', key: 'sport' },
    { label: 'Count', key: 'count' }
  ];

  const calendarRef = useRef<HTMLDivElement | null>(null);

  const handleOutsideClick = useCallback(
    (e: MouseEvent) => {
      if (open && !calendarRef.current?.contains(e.target as Node)) {
        setOpen(false);
      }
    },
    [open]
  );

  useEffect(() => {
    window.addEventListener('click', handleOutsideClick);

    return () => {
      window.removeEventListener('click', handleOutsideClick);
    };
  }, [handleOutsideClick]);

  return (
    <StyledChartHeader>
      <Space justify="space-between" align="center">
        {date ? (
          <div className="flex flex-display flex-direction-row flex-justify-content-center flex-align-items-center">
            <h3>{title} /</h3>
            <StyledDateButton
              ref={calendarRef}
              onMouseEnter={() => {
                setCalendarColor(true);
              }}
              onMouseLeave={() => {
                setCalendarColor(false);
              }}
            >
              {date && (
                <StyledDateWrapper hover={calendarColor}>
                  <DateRangePickerNew
                    key={`${range.startDate}`}
                    dateRange={[
                      dayjs(range.startDate).local().toDate(),
                      dayjs(range.endDate).toDate()
                    ]}
                    onChange={value => {
                      setRange({
                        startDate: value.startDate as Date,
                        endDate: value.endDate as Date
                      });

                      if (setItem && dateItem) {
                        setItem({
                          itemName: dateItem?.itemName || '',
                          value: {
                            startDate: dayjs(value.startDate).format(
                              'YYYY-MM-DD HH:mm:ss'
                            ),
                            endDate: dayjs(value.endDate).format(
                              'YYYY-MM-DD HH:mm:ss'
                            )
                          }
                        });
                      }
                    }}
                    variant="borderless"
                    onOpen={() => setOpen(!open)}
                    staticRanges={[
                      {
                        label: t('dates:today'),
                        value: getStaticRangesArray('day')
                      },
                      {
                        label: t('dates:this_week'),
                        value: getStaticRangesArray('week')
                      },
                      {
                        label: t('dates:this_month'),
                        value: getStaticRangesArray('month')
                      },
                      {
                        label: t('dates:this_year'),
                        value: getStaticRangesArray('year')
                      }
                    ]}
                  />
                </StyledDateWrapper>
              )}
            </StyledDateButton>
          </div>
        ) : (
          <h3>{title}</h3>
        )}

        <StyledIconsBox>
          {exportData && (
            <Private
              routeId={permissionConstants.BUYER_ROUTES_IDS.reports}
              routeKey={permissionConstants.EXPORT}
            >
              <StylesExportBut
                show={true}
                onMouseEnter={() => {
                  setExportIconColor(true);
                }}
                onMouseLeave={() => {
                  setExportIconColor(false);
                }}
                backgroundColor={exportIconColor}
              >
                {dataT && (
                  <>
                    <CSVLink
                      style={{ display: 'flex' }}
                      data={dataT}
                      headers={headersForMapData}
                      filename="mapData.csv"
                    >
                      <Icon
                        type="export"
                        color={!exportIconColor ? colors.blue[2] : colors.white}
                        size={24}
                      />
                    </CSVLink>
                    <StyledExportBox show={exportIconColor}>
                      <CSVLink
                        data={dataT}
                        headers={headersForPieData}
                        filename="mapData.csv"
                        style={{ color: 'black' }}
                      >
                        Export
                      </CSVLink>
                    </StyledExportBox>
                  </>
                )}
              </StylesExportBut>
            </Private>
          )}
          {icons && (
            <StyledTest>
              <StyledSwitch movement={isSwitch} isType={type}>
                <StyledSwitchIcon
                  position="left"
                  onClick={() => {
                    setSwitch('table');
                    setChangePage?.('table');
                  }}
                >
                  <Icon
                    type={icons[0]}
                    color={isSwitch === 'table' ? colors.white : 'var(--gray)'}
                    size={24}
                  />
                </StyledSwitchIcon>
                <StyledSwitchIcon
                  position="right"
                  onClick={() => {
                    setSwitch('chart');
                    setChangePage?.('chart');
                  }}
                >
                  <Icon
                    type={icons[1]}
                    color={isSwitch === 'chart' ? colors.white : 'var(--gray)'}
                    size={24}
                  />
                </StyledSwitchIcon>
                {type === 'threePage' && (
                  <StyledSwitchIcon
                    position="three"
                    onClick={() => {
                      setSwitch('three');
                      setChangePage?.('three');
                    }}
                  >
                    <Icon
                      type={icons[2]}
                      color={
                        isSwitch === 'three' ? colors.white : 'var(--gray)'
                      }
                      size={24}
                    />
                  </StyledSwitchIcon>
                )}
              </StyledSwitch>
            </StyledTest>
          )}
        </StyledIconsBox>
      </Space>
    </StyledChartHeader>
  );
};

export default memo(ChartsHeader);
