import styled from '@shared_medialab/ui_components';

export const StyledMatchDetails = styled.div`
  padding: ${({ theme }) => `${theme.spaces.xl} 0`};
  flex: 1;
`;

export const StyledWrapper = styled.div`
  height: 100%;
  display: flex;
  flex-direction: column;
  flex: 1;
`;

export const StyledContent = styled.div`
  flex: 1;
`;
