import { FC, useCallback, useEffect, useMemo, useState } from 'react';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import * as permissionConstants from 'constants/permissions';
import { usePermission, useStatusState } from 'hooks';
import {
  BmeStatuses,
  ProductStatuses,
  ProjectStatuses
} from 'providers/Projects/types';
import { MatchTypesEnum } from 'constants/match';
import { Button, PageContainer, Tooltip } from '@shared_medialab/ui_components';
import { useAppSelector } from 'store/hooks';
import { selectFormattedEventsFilters } from 'store/slice/filters/events/selector';
import { useProjectStateContext } from 'providers/Projects';
import { shallowEqual } from 'react-redux';
import qs from 'qs';
import axios from 'axios';

import { RouteParams } from './components/Content/types';
import Private from 'components/shared/Private';
import Events from '.';
import AutoBooking from 'pages/AutoBooking';
import useEventsHistoryCache from 'hooks/useEventsHistoryCache';
import useEventsBookedCache from 'hooks/useEventsBookedCache';
import useEventsUpcomingCache from 'hooks/useEventUpcomingCache';

const DEFAULT_PATH = '/streaming/events';
const ROUTES = {
  list: '/streaming/events/list',
  booked: '/streaming/events/booked',
  history: '/streaming/events/history',
  autobooking: '/streaming/events/autobooking'
};

const EventsWrapper: FC = () => {
  //state
  const [loading, setLoading] = useState(false);

  // navigation
  const { pathname } = useLocation();
  const navigate = useNavigate();
  const { type } = useParams() as RouteParams;

  //redux
  const { checkUserPermission } = usePermission();

  const historyFilters = useAppSelector(
    selectFormattedEventsFilters(MatchTypesEnum.history),
    shallowEqual
  );

  // context
  const { selectedProject } = useProjectStateContext();
  const baseURL = `${process.env.REACT_APP_BUYER_API_URL}/matches/history/${selectedProject?.id}`;

  const isTabsEnabled = useStatusState({
    product: [
      ProductStatuses.active,
      ProductStatuses.pending,
      ProductStatuses.integration,
      ProductStatuses.trial,
      ProductStatuses.inactive
    ]
  });

  const onlyList = useStatusState({
    product: [ProductStatuses.pending],
    bme: [BmeStatuses.live, BmeStatuses.trial],
    project: [ProjectStatuses.live]
  });

  const reportAndHistory = useStatusState({
    product: [ProductStatuses.inactive],
    bme: [
      BmeStatuses.live,
      BmeStatuses.trial,
      BmeStatuses.terminated,
      BmeStatuses.lost,
      BmeStatuses.in_active
    ],
    project: [ProjectStatuses.terminated]
  });

  const nothingIsAvailable =
    useStatusState({
      product: [ProductStatuses.trial],
      bme: [BmeStatuses.live],
      project: [ProjectStatuses.live]
    }) && selectedProject?.trialCount === '0';

  const onExport = useCallback(() => {
    setLoading(true);
    const token = localStorage.getItem('token');

    const queryString = qs.stringify(historyFilters, {
      arrayFormat: 'repeat'
    });

    const url = `${baseURL}?${queryString}`;

    axios
      .get(url, {
        responseType: 'blob',
        headers: {
          'Content-Type':
            'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
          Authorization: `Bearer ${token}`
        }
      })
      .then(response => {
        const contentType = response.headers['content-type'];

        const blob = new Blob([response.data], { type: contentType });
        const url = window.URL.createObjectURL(blob);
        const link = document.createElement('a');
        link.href = url;
        link.setAttribute('download', 'History.xlsx');
        document.body.appendChild(link);
        link.click();
        link.parentNode?.removeChild(link);
        setLoading(false);
      })
      .catch(error => {
        console.error('error', error);
        setLoading(false);
      });
  }, [baseURL, historyFilters]);

  const listTab = useMemo(() => {
    const hasPermission = checkUserPermission(
      permissionConstants.BUYER_ROUTES_IDS.list,
      permissionConstants.VIEW
    );

    if (hasPermission) {
      return {
        id: ROUTES.list,
        action() {
          navigate(ROUTES.list);
        },
        name: MatchTypesEnum.list,
        disabled: !isTabsEnabled || reportAndHistory
      };
    }
  }, [checkUserPermission, isTabsEnabled, navigate, reportAndHistory]);

  const bookedTab = useMemo(() => {
    const hasPermission = checkUserPermission(
      permissionConstants.BUYER_ROUTES_IDS.booked,
      permissionConstants.VIEW
    );

    if (hasPermission) {
      return {
        id: '/streaming/events/booked',
        action() {
          navigate('/streaming/events/booked');
        },
        name: MatchTypesEnum.booked,
        disabled: !isTabsEnabled || onlyList || reportAndHistory
      };
    }
  }, [
    checkUserPermission,
    isTabsEnabled,
    navigate,
    onlyList,
    reportAndHistory
  ]);

  const historyTab = useMemo(() => {
    const hasPermission = checkUserPermission(
      permissionConstants.BUYER_ROUTES_IDS.history,
      permissionConstants.VIEW
    );

    if (hasPermission) {
      return {
        id: ROUTES.history,
        name: 'history',
        action: () => {
          navigate(ROUTES.history);
        },
        disabled: !isTabsEnabled || onlyList
      };
    }
  }, [checkUserPermission, isTabsEnabled, navigate, onlyList]);

  const autobookingTab = useMemo(() => {
    const hasPermission = checkUserPermission(
      permissionConstants.BUYER_ROUTES_IDS.autoBooking,
      permissionConstants.VIEW
    );

    if (hasPermission) {
      return {
        id: ROUTES.autobooking,
        action() {
          navigate(ROUTES.autobooking);
        },
        name: 'Autobooking',
        disabled: !isTabsEnabled || onlyList || reportAndHistory
      };
    }
  }, [
    checkUserPermission,
    isTabsEnabled,
    navigate,
    onlyList,
    reportAndHistory
  ]);

  const tabs = useMemo(() => {
    const values = [];

    if (listTab) {
      values.push(listTab);
    }

    if (bookedTab) {
      values.push(bookedTab);
    }

    if (historyTab) {
      values.push(historyTab);
    }

    if (autobookingTab) {
      values.push(autobookingTab);
    }

    return values;
  }, [listTab, bookedTab, historyTab, autobookingTab]);

  useEffect(() => {
    if (pathname === DEFAULT_PATH && tabs[0]?.id) {
      navigate(tabs[0].id);
    }
  }, [navigate, pathname, tabs]);

  useEffect(() => {
    if (reportAndHistory) {
      navigate(permissionConstants.BUYER_ROUTES_PATHS.history);
    }

    if (onlyList) {
      navigate(permissionConstants.BUYER_ROUTES_PATHS.list);
    }

    if (nothingIsAvailable) {
      navigate('/streaming/notFound');
    }
  }, [
    navigate,
    nothingIsAvailable,
    onlyList,
    pathname,
    reportAndHistory,
    selectedProject?.trialCount,
    tabs
  ]);
  const renderRightActions = useCallback(() => {
    switch (pathname) {
      case ROUTES.history:
        return (
          <div className="flex-display flex-align-items-center pr--12">
            <Tooltip
              title="Export"
              styles={{
                textColor: 'black',
                bgColor: 'white'
              }}
            >
              <Button
                color="export"
                appearance="light"
                flexibility="content-size"
                size="default"
                icon="export"
                iconSize={20}
                cornerRadius="smooth"
                height="auto"
                onClick={onExport}
                loading={loading}
                disabled={loading}
              />
            </Tooltip>
          </div>
        );
    }
  }, [loading, onExport, pathname]);

  // graphql
  const { onScroll: upcoming } = useEventsUpcomingCache();
  const { onScroll: booked } = useEventsBookedCache();
  const { onScroll: history } = useEventsHistoryCache();

  const onScroll = useCallback(() => {
    switch (pathname) {
      case ROUTES.list:
        return upcoming;
      case ROUTES.booked:
        return booked;
      case ROUTES.history:
        return history;
    }
  }, [booked, history, pathname, upcoming]);

  if (!tabs.length || !type) {
    return null;
  }

  return (
    <PageContainer
      tabs={tabs}
      activeTab={location.pathname}
      bodyStyle={{ paddingRight: '10px' }}
      rightAction={renderRightActions()}
      onScroll={onScroll()}
    >
      <Private
        routeId={
          permissionConstants.BUYER_ROUTES_IDS[
            pathname === ROUTES.autobooking ? 'autoBooking' : type
          ]
        }
        routeKey={permissionConstants.VIEW}
      >
        {pathname === ROUTES.autobooking ? <AutoBooking /> : <Events />}
      </Private>
    </PageContainer>
  );
};

export default EventsWrapper;
