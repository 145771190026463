import { Card, Input, Space } from '@shared_medialab/ui_components';
import { PATTERN } from 'constants/validation';
import { useModalStateContext } from 'providers/UI';
import { useUserFormStateContext } from 'providers/UserForm';
import tldjs from 'tldjs';
import { FC } from 'react';
import { Controller } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { PENDING } from 'constants/users';

import { StyledPersonalDetails, StyledResendEmailContainer } from './styled';
import ResendEmailButton from '../ResendEmailButton';

const PersonalDetails: FC<{
  userStatus: string;
}> = ({ userStatus }) => {
  // translations
  const { t } = useTranslation();
  // context
  const { control } = useUserFormStateContext();
  const { userDetails } = useModalStateContext();

  const userId = userDetails?.params?.userId;

  return (
    <StyledPersonalDetails>
      <Card
        header={{
          title: t('users:personal_details')
        }}
        style={{ height: '100%' }}
      >
        <Space direction="column" size="sm">
          <Controller
            render={({ field: { value, onChange }, fieldState: { error } }) => (
              <div className="relative-position">
                <Input
                  label={t('users:email')}
                  placeholder={t('users:type_email')}
                  cornerRadius="smooth"
                  disabled={!!userId}
                  errorText={error?.message}
                  value={value}
                  onChange={onChange}
                />
                {userStatus === PENDING && (
                  <StyledResendEmailContainer className="absolute-position">
                    <ResendEmailButton userId={userId} />
                  </StyledResendEmailContainer>
                )}
              </div>
            )}
            rules={{
              required: {
                value: true,
                message: t('messages:field_required')
              },
              pattern: {
                value: PATTERN.email,
                message: t('messages:invalid_email')
              },
              validate: value => {
                const isValidTLD = tldjs.tldExists(value);

                if (!isValidTLD) {
                  return t('messages:invalid_email');
                }

                return true;
              }
            }}
            name="email"
            control={control}
          />
          <Controller
            render={({ field: { value, onChange }, fieldState: { error } }) => (
              <Input
                label={t('users:name')}
                placeholder={t('users:type_name')}
                cornerRadius="smooth"
                errorText={error?.message}
                value={value}
                onChange={onChange}
              />
            )}
            rules={{
              required: {
                value: true,
                message: t('messages:field_required')
              }
            }}
            name="firstName"
            control={control}
          />
          <Controller
            render={({ field: { value, onChange }, fieldState: { error } }) => (
              <Input
                label={t('users:surname')}
                placeholder={t('users:type_surname')}
                cornerRadius="smooth"
                errorText={error?.message}
                value={value}
                onChange={onChange}
              />
            )}
            rules={{
              required: {
                value: true,
                message: t('messages:field_required')
              }
            }}
            name="lastName"
            control={control}
          />
        </Space>
      </Card>
    </StyledPersonalDetails>
  );
};

export default PersonalDetails;
