import { Row } from '@shared_medialab/ui_components';
import styled from 'styled-components';

export const StyledEditCardContent = styled(Row)`
  background-color: var(--b);
  padding: 5px;
  display: flex;
  align-items: center;
`;
export const StyledSwitch = styled.div`
  background-color: var(--b);
  border-radius: 0 0 5px 5px;
`;
