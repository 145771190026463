import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import dayjs from 'dayjs';

import {
  OnChange,
  OnChangePage,
  OnChangeTotal,
  OnDatesChange,
  ReportsFiltersKeys,
  ReportsFiltersState
} from './types';
import { removeSpaces } from 'utils/removeSpaces';
import { parseJson } from 'utils/object';

const defaultFilters = {
  dates: {
    startDate: dayjs()
      .subtract(1, 'week')
      .startOf('day')
      .format('YYYY-MM-DD HH:mm:ss'),
    endDate: dayjs().endOf('day').format('YYYY-MM-DD HH:mm:ss')
  },
  countryNames: [],
  sport: [],
  region: [],
  league: [],
  platform: [],
  overViewDate: {
    startDate: '',
    endDate: ''
  },
  dailyViewsDate: {
    startDate: '',
    endDate: ''
  },
  sportsDate: {
    startDate: '',
    endDate: ''
  },
  platformsDate: {
    startDate: '',
    endDate: ''
  },
  locationsDate: {
    startDate: '',
    endDate: ''
  },
  eventsDate: {
    startDate: '',
    endDate: ''
  }
};

const defaultPagination: ReportsFiltersState['pagination'] = {
  [ReportsFiltersKeys.buyers]: {
    page: 0,
    total: 0
  },
  [ReportsFiltersKeys.contentGroup]: {
    page: 0,
    total: 0
  },
  [ReportsFiltersKeys.useGroup]: {
    page: 0,
    total: 0
  },
  [ReportsFiltersKeys.events]: {
    page: 0,
    total: 0
  },
  [ReportsFiltersKeys.location]: {
    page: 0,
    total: 0
  },
  [ReportsFiltersKeys.osPlatform]: {
    page: 0,
    total: 0
  },
  [ReportsFiltersKeys.overView]: {
    page: 0,
    total: 0
  },
  [ReportsFiltersKeys.sport]: {
    page: 0,
    total: 0
  },
  [ReportsFiltersKeys.uniqView]: {
    page: 0,
    total: 0
  }
};

const initialState: ReportsFiltersState = {
  filters: {
    ...defaultFilters,
    ...(parseJson(localStorage.getItem('reportsFilters')) || {})
  },
  pagination: {
    ...defaultPagination
  }
};

export const reportsFilterSlice = createSlice({
  name: 'filters',
  initialState,
  reducers: {
    setFilterItem: (state, action: PayloadAction<OnChange>) => {
      const { name, value } = action.payload;
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      state.filters[name] = removeSpaces(value);
      localStorage.setItem('reportsFilters', JSON.stringify(state.filters));
    },
    resetReportsFilters: state => {
      state.filters = { ...defaultFilters };
      state.pagination = { ...initialState.pagination };
      localStorage.removeItem('reportsFilters');
    },
    setReportsDateItems: (state, action: PayloadAction<OnDatesChange>) => {
      const { value } = action.payload;

      state.filters.overViewDate = value;
      state.filters.dailyViewsDate = value;
      state.filters.sportsDate = value;
      state.filters.platformsDate = value;
      state.filters.locationsDate = value;
      state.filters.eventsDate = value;
    },
    setReportsPage: (state, action: PayloadAction<OnChangePage>) => {
      const { key, page } = action.payload;

      state.pagination[key].page = page;
    },
    setReportsTotal: (state, action: PayloadAction<OnChangeTotal>) => {
      const { key, total } = action.payload;

      state.pagination[key].total = total;
    },
    resetReportsMeta: state => {
      state.pagination = { ...initialState.pagination };
    }
  }
});

export const {
  setFilterItem,
  resetReportsFilters,
  setReportsPage,
  setReportsTotal,
  resetReportsMeta,
  setReportsDateItems
} = reportsFilterSlice.actions;

export default reportsFilterSlice.reducer;
