import { useProjectStateContext } from 'providers/Projects';
import { ProductStatuses } from 'providers/Projects/types';
import { FC, memo } from 'react';
import { useTranslation } from 'react-i18next';

import Button from './components/Button';

const StatusButton: FC = () => {
  // translations
  const { t } = useTranslation('header');
  // context
  const { selectedProject } = useProjectStateContext();

  switch (selectedProject?.status) {
    case ProductStatuses.pending:
      return (
        <Button
          statusText={t('pending_text')}
          btnText={t('pending')}
          mode="warning"
        />
      );
    case ProductStatuses.integration:
      return (
        <Button
          statusText={t('integration_text')}
          btnText={t('integration')}
          mode="integration"
        />
      );

    case ProductStatuses.trial: {
      if (selectedProject?.trialCount !== '0' && !selectedProject?.trialCount) {
        return (
          <Button
            statusText={t('trial_text')}
            btnText={t('trial')}
            mode="trial"
          />
        );
      }

      const daysLeft = +selectedProject?.trialCount || 0;

      if (daysLeft <= 0) {
        return (
          <Button
            statusText={t('activate_request')}
            btnText={t('activate')}
            mode="activation"
          />
        );
      }

      return (
        <Button
          statusText={t(
            `${daysLeft === 0 ? 'trial_expired_text' : 'trial_text'}`
          )}
          btnText={`${t('trial')}/${daysLeft}d`}
          mode="trial"
        />
      );
    }

    case ProductStatuses.inactive:
      return (
        <Button
          statusText={t('inactive_text')}
          btnText={t('inactive')}
          mode="danger"
        />
      );

    default:
      return (
        <Button
          statusText={t('active_text')}
          btnText={t('active')}
          mode="success"
        />
      );
  }
};

export default memo(StatusButton);
